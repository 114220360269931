<template>
  <div>
    <div class="search-bar">
      <a-form-model :labelCol="{ span: 8 }"
                    :wrapperCol="{ span: 16 }"
                    ref="searchRef"
                    :model="searchForm">
        <a-row>
          <!-- 第一排 -->
          <a-col :span="6">
            <a-form-model-item prop="order_no" label="订单ID">
              <a-input v-model="searchForm.order_no" allowClear enter-button placeholder="请输入订单ID"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_source" label="订单来源">
              <a-select placeholder="请选择订单来源" v-model="searchForm.order_source">
                <a-select-option
                  v-for="(item, index) in orderSourceOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_name"
                               label="收/提货人">
              <a-input allowClear
                       v-model="searchForm.consignee_recipient_name"
                       placeholder="请输入收/提货人"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_phone"
                               label="收/提货手机号">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.consignee_recipient_phone"
                       placeholder="请输入手机号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!-- 第二排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="goods_name"
                               label="商品名称">
              <a-input v-model="searchForm.goods_name"
                       enter-button
                       allowClear
                       placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_amount_start"
                               label="订单金额"
                               class="flex">
              <div class="flex items-center">
                <a-input-number id="inputNumber" style="flex:1;"
                                v-model="searchForm.order_amount_start"
                                :min="1" />
                <span class="ml-2 mr-2">~</span>
                <a-input-number id="inputNumber2" style="flex:1;"
                                v-model="searchForm.order_amount_end"
                                :min="1" />
              </div>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item prop="order_add_time_arr"
                               label="下单日期">
              <a-range-picker :show-time="{ format: 'HH:mm:ss' }"
                    v-model="searchForm.order_add_time_arr"
                    valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item prop="pay_mode"
                               label="支付方式">
              <a-select placeholder="请选择支付方式"
                        v-model="searchForm.pay_mode">
                <a-select-option v-for="(item, index) in orderModalOptions"
                                 :key="index"
                                 :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="pay_flow_no"
                               label="支付流水号">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.pay_flow_no"
                       placeholder="请输入支付流水号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        
        <!-- 下单时间、收货时间 -->
        <a-row v-if="expand">
          <a-col :span="12">
            <a-form-model-item prop="order_add_time_arr" label="下单时间" 
                               :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-range-picker :show-time="{ format: 'HH:mm:ss' }" class="date-time"
                    :placeholder="['开始时间', '结束时间']"
                    v-model="searchForm.order_add_time_arr"
                    valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="consignee_recipient_time_arr" label="收/提货时间"
                               :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-range-picker :show-time="{ format: 'HH:mm:ss' }" class="date-time"
                    :placeholder="['开始时间', '结束时间']"
                    v-model="searchForm.consignee_recipient_time_arr"
                    valueFormat="YYYY-MM-DD HH:mm:ss" />
            </a-form-model-item>
          </a-col>
        </a-row>
        
        <!-- 第三排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="order_status"
                               label="订单状态">
              <a-select placeholder="请选择订单状态"
                        v-model="searchForm.order_status">
                <a-select-option v-for="(item, index) in orderStatusOptions"
                                 :key="index"
                                 :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="orderer_phone"
                               label="订货人电话">
              <a-input allowClear
                       v-model="searchForm.orderer_phone"
                       enter-button
                       placeholder="下单人/联系人电话"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="consignee_address"
                               label="收货地址">
              <a-input allowClear
                       enter-button
                       v-model="searchForm.consignee_address"
                       placeholder="请输入收货地址"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="order_remarks"
                               label="下单备注">
              <a-input allowClear
                       v-model="searchForm.order_remarks"
                       enter-button
                       placeholder="请输入备注"></a-input>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item prop="consignee_recipient_time_arr"
                               label="收/提货日期">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.consignee_recipient_time_arr" />
            </a-form-model-item>
          </a-col> -->
        </a-row>

        <!-- 第四排 -->
        <a-row v-if="expand">
          <a-col :span="6">
            <a-form-model-item prop="actual_sale_ascription_outlets_id" label="负责网点">
              <a-select show-search allowClear
                        placeholder="请输入负责网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="initOutletsList"
                        v-model="searchForm.actual_sale_ascription_outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="order_type" label="配送方式">
              <a-select placeholder="请选择配送方式" v-model="searchForm.order_type">
                <a-select-option
                  v-for="(item, index) in orderTypeOptions"
                  :key="index"
                  :value="item.id"
                >{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <!-- <a-col :span="6">
            <a-form-model-item prop="goods_type"
                               label="商品类型">
              <a-select v-model="searchForm.goods_type"
                        placeholder="商品分类">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option v-for="item in Object.keys(goodsType)"
                                 :value="Number(item)"
                                 :key="item">{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item prop="acceptance_time_arr" label="受理时间">
              <a-range-picker v-model="searchForm.acceptance_time_arr" valueFormat="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="dispatch_staff_id" label="调度人">
              <a-select show-search allowClear
                        placeholder="请输入调度人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.dispatch_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <!-- 第五排 -->
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="order_finish_time_arr"
                               label="完成日期">
              <a-range-picker valueFormat="YYYY-MM-DD"
                              v-model="searchForm.order_finish_time_arr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="create_staff_id"
                               label="创建人">
              <a-select show-search allowClear
                        placeholder="请输入创建人"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="getStaffList"
                        v-model="searchForm.create_staff_id">
                <a-select-option v-for="(item, index) in staffList"
                                 :key="index"
                                 :value="item.staff_id">{{item.staff_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
                               prop="is_update" label="是否修改">
              <div class="flex justify-between items-center" style="flex:1;">
                <a-checkbox-group v-model="searchForm.is_update"
                                  :options="orderIsModifyList"
                                  style="width:270px" />
                <div class="flex items-center justify-end mr-1 mt-1">
                  <a style="font-size:12px;" @click="handlerExpand">
                    {{!expand?'展开':'收起'}}
                    <a-icon :type="expand ? 'up' : 'down'" />
                  </a>
                  <a-button class="ml-4" @click="resetForm">重置</a-button>
                  <a-button class="ml-2" type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
                </div>
              </div>
                
            </a-form-model-item>
          </a-col>

        </a-row>

      </a-form-model>
    </div>

    <base-table ref="orderTableRef"
                id="orderTableID"
                :key="tableKey"
                :columnsData="columns"
                :rowKey="rowKey"
                :tableData="tableData"
                :total="total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">订单列表</h4>
          <a-button type="primary"
                    @click="hanlderNewOrder"
                    html-type="submit">新建订单</a-button>
        </div>
      </template>

      <template #time="{ record }">
        <span>{{formatTime(record.consignee_recipient_time_start)}}~{{formatTime(record.consignee_recipient_time_end.split(' ')[1])}}</span>
      </template>
      <template #order_status="{ text }">
        <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
        <a-tag class="mr-0"
          :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
        >{{ formatDetailOrderStatus(text) }}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" @click="handlerDetailBlank(record)">详情</a-button>
        <!-- <a v-if="record.order_status !=-1 && (record.is_deliverying==2 || record.is_express==2)"
          class="ml-2"
          type="link"
          @click="handlerQuery(record)"
        >配送查询</a> -->
      </template>
    </base-table>

    <edit-stock-modal v-if="isShowEdit"
                      :show.sync="isShowEdit"
                      :skuId="activeSkuId" />
    <current-stock-modal v-if="isShowEditCurrent"
                         :show.sync="isShowEditCurrent"
                         :skuId="activeSkuId" />
    <!-- <pay-modal v-if="showPay"
               :show.sync="showPay"
               :orderId="activeOrderNO"
               :totalAmount="Number(activeRow.order_amount)"></pay-modal> -->

    <!-- 抽屉展示订单详情 -->
    <a-drawer class="title-in-page"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowDetail"
              @close="isShowDetail = false">
     <!--  <template slot="title">
        <div class="drawer-class">
          <div>{{`订单ID: ${activeOrderNO} (${formatDetailOrderStatus(activeRow.order_status || 1)})`}}</div>
        </div>
      </template> -->
      <orderDetail :orderNo="activeOrderNO"
                   v-if="isShowDetail"
                   @getDetail="(row)=>{activeRow=row}"
                   @order-canceled="initData()"></orderDetail>
    </a-drawer>

    <!-- 地图 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="activeOrderNO" 
        :hasDelivery="activeOrder_hasDelivery" 
        :hasExpress="activeOrder_hasExpress" />

  </div>
</template>

<script>
import moment from "moment"
import { orderSourceList } from "@/utils/type"
import pageData from "./columns"
import {
  getAllOrderList,
  getOutletsList,
  getStaffList,
} from "@/api/customer.js"
import { formatDetailOrderStatus, payTypeList, orderTypeList } from "@/utils/type"

import orderDetail from "./detail.vue"
// import PayModal from './components/pay-modal.vue'
import QueryWindow from "@/components/order/queryWindow"

export default {
  components: {
    orderDetail,
    QueryWindow
  },
  data () {
    return {
      ...pageData,
      // showPay: false,
      activeOrderNO: "",
      activeOrder_hasDelivery: false,
      activeOrder_hasExpress: false,
      activeRow: {},

      expand: false,
      isShowDetail: false,
      tableKey: 0,

      total: 0,
      tableData: [],
      outletsList: [],
      staffList: [],
      searchForm: {
        order_no: "",
        order_source: 0,
        order_status: 0,
        is_update: [],
        orderer_phone: "",
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        order_remarks: "",
        consignee_recipient_name: "",
        consignee_recipient_phone: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        consignee_address: "",
        actual_sale_ascription_outlets_id: undefined,
        order_type: 0,
        goods_name: "",
        goods_type: 0,
        order_amount_start: "",
        order_amount_end: "",
        pay_flow_no: "",
        pay_mode: 0,
        order_finish_time_arr: [],
        order_finish_time_start: "",
        order_finish_time_end: "",
        create_staff_id: undefined,
        dispatch_staff_id: undefined,
        acceptance_time_arr: [],
        acceptance_time_start: "",
        acceptance_time_end: "",
        page: 1,
        page_count: 20,
      },
      rowKeys: [],
      isShowQuery: false,
      isShowEdit: false,
      isShowEditCurrent: false,
      activeSkuId: 0,
      orderTypeOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderTypeList),
      orderModalOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(payTypeList),
      orderSourceOptions: [{
          id: 0, 
          name: "全部" 
      }].concat(orderSourceList),
      // orderStatusOptions: [{
      //     id: 0, 
      //     name: "全部" 
      // }].concat(orderStatusList),
    }
  },
  watch: {
    // activeRow () {
    //   if(this.$route.query.orderId){
    //     if (this.activeRow.order_status == 1 && this.activeRow.order_source == 10) {
    //       this.showPay = true
    //     }
    //   }
    // }
  },
  mounted () {
    // 如果传入orderId则默认展开该id的详情
    if (this.$route.query.orderId) {
      this.activeOrderNO = this.$route.query.orderId
      this.isShowDetail = true
    } else if (this.$route.query.memberId) {
      this.initData({
        member_id: this.$route.query.memberId
      })
      return
    }

    this.initData()
  },
  methods: {
    moment,
    formatDetailOrderStatus,
    async initData (otherParams) {

      console.log(this.searchForm.order_add_time_arr)

      otherParams = otherParams || {};
      const params = Object.assign(otherParams, JSON.parse(JSON.stringify(this.searchForm)))
      // if(this.memberId){
      //   params.member_id = this.memberId
      // }
      if (params.order_add_time_arr.length > 0) {
        params.order_add_time_start = params.order_add_time_arr[0]
        params.order_add_time_end   = params.order_add_time_arr[1]
        // if(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(params.order_add_time_start)){
        //   params.order_add_time_start = params.order_add_time_start+":00"
        // }
        // if(/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(params.order_add_time_end)){
        //   params.order_add_time_end = params.order_add_time_end+":59"
        // }
      }
      if (params.consignee_recipient_time_arr.length > 0) {
        params.consignee_recipient_time_start = params.consignee_recipient_time_arr[0]
        params.consignee_recipient_time_end   = params.consignee_recipient_time_arr[1]
      }
      if (params.acceptance_time_arr.length > 0) {
        params.acceptance_time_start = params.acceptance_time_arr[0] + " 00:00:00"
        params.acceptance_time_end   = params.acceptance_time_arr[1] + " 23:59:59"
      }

      if (params.order_finish_time_arr.length > 0) {
        params.order_finish_time_start = params.order_finish_time_arr[0] + " 00:00:00"
        params.order_finish_time_end   = params.order_finish_time_arr[1] + " 23:59:59"
      }

      params.is_update = params.is_update.join(",")
      const { data, code } = await getAllOrderList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    async initOutletsList (value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },
    async getStaffList (value) {
      if (value) {
        const { data, code } = await getStaffList({
          staff_name: value,
        })
        if (code === 0) this.staffList = data.list
      }
    },


    hanlderNewOrder () {
      // this.$router.push(`/order-add`)
      const newPage = this.$router.resolve({
        name: "order-add",
      });
      window.open(newPage.href, "_blank");
    },
    handlerSearch () {
      this.searchForm.page = 1
      this.initData()
    },
    resetForm () {
      this.searchForm.page = 1
      this.searchForm.page_count = 10
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    handlerDetail (row) {
      // this.activeRow = row
      this.activeRow = {}
      this.activeOrderNO = row.order_no
      this.isShowDetail = true
    },
    handlerDetailBlank(row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },
    // 物流
    // handlerQuery(e) {
    //   this.activeOrderNO = e.order_no
    //   this.activeOrder_hasDelivery = e.is_deliverying==2
    //   this.activeOrder_hasExpress  = e.is_express==2
    //   this.isShowQuery = true
    // },

    handlerExpand () {
      this.expand = !this.expand
      ++this.tableKey
    },

    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
    }
  },
}
</script>

<style lang="less">
.search-bottom-class {
  margin-bottom: 20px;
  margin-left: 50px;
}

.drawer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelOrder-class {
  margin-right: 50px;
}

.date-time{
  width: 100% !important;
}
</style>
